import {defineStore} from 'pinia';

export const opinionStore = defineStore({
    id: 'opinionStore',
    state: () => ({
        addArgumentsModalState: false
    }),
    actions: {
        toggleAddArgumentsModalState() {
            this.addArgumentsModalState = !this.addArgumentsModalState;
        }
    }
});