<script>
import {mapState} from "pinia";
import {proposalStore} from "@/store/proposalStore";
import Carousel from 'primevue/carousel';
import ArgumentsListItem from "@/views/arguments/ArgumentsListItem.vue";
import {argumentStore} from "@/store/argumentStore";

export default {
  name: "ProposalArgumentsList",
  components: {
    Carousel,
    ArgumentsListItem
  },
  computed: {
    ...mapState(argumentStore, [
      'activeArgument'
    ]),
    ...mapState(proposalStore, [
        'proposalArguments'
    ]),
    argumentsWithContent() {
      return this.proposalArguments.filter((arg)=> {
        return arg.argumentDetailed;
      })
    }
  },
  mounted() {

  }
}
</script>

<template>
<div class="argument-sist-section">
  <div class="arguments-list">
    <span class="arguments-list__title">Arguments created by proposer ({{proposalArguments.length}})</span>
    <Carousel :value="argumentsWithContent" :numVisible="4" :numScroll="1" :showIndicators="false">
      <template #previcon>
        <div class="arguments-list-control arguments-list-control--prev">
          <font-awesome-icon :icon="['fas', 'arrow-left']"
                             class="arguments-list-control__icon"
          />
        </div>
      </template>
      <template #item="slotProps">
        <arguments-list-item :arg-data="slotProps.data"
                             :is-selected-arg="activeArgument.id === slotProps.data.id"
        />
      </template>
      <template #nexticon>
        <div class="arguments-list-control arguments-list-control--next">
          <font-awesome-icon :icon="['fas', 'arrow-right']"
                             class="arguments-list-control__icon"
          />
        </div>
      </template>
    </Carousel>
  </div>
</div>
</template>

<style lang="scss" >
.argument-sist-section {
  width: 100%;
  height: 328px;
  margin-bottom: 10px;
  background: #fff;

  .arguments-list {
    position: relative;
    //display: flex;
    padding: 40px 10px 20px;
    //gap: 15px;
    &__title {
      position: absolute;
      top: 15px;
      left: 10px;
      display: block;
      margin-bottom: 5px;
      font-size: 13px;
      font-weight: 600;
      white-space: nowrap;
    }
  }

  .arguments-list-control {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    box-shadow: 0 2px 6px 0 rgba(79, 79, 79, 0.3);
    border-radius: 30px;
    background-color: #fff;

    &__icon {
      font-size: 16px;
    }
  }

  .p-carousel-content {
    position: relative;

    .p-carousel-item {
      max-width: 265px;
    }

    .p-button {
      position: absolute;
      z-index: 1;

      &.p-disabled {
        display: none;
      }

      &.p-carousel-prev-button {
        left: 0;
        top: 55px;
        &:before {
          content: '';
          display: block;
          position: absolute;
          top: -90px;
          left: -20px;
          width: 22px;
          height: 300px;
          background-image: linear-gradient(270deg, rgba(255, 255, 255, 0), #fff)
        }
      }
      &.p-carousel-next-button {
        right: 0;
        top: 55px;
        &:before {
          content: '';
          display: block;
          position: absolute;
          top: -90px;
          right: -20px;
          width: 22px;
          height: 300px;
          background-image: linear-gradient(270deg, rgba(255, 255, 255, 0), #fff)
        }
      }
    }
  }
}
</style>