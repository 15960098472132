<script>
import {mapActions, mapState} from "pinia";
import {bankIdStore} from "@/store/bankIdStore";
import axios from 'axios';
import QrcodeVue from 'qrcode.vue'

export default {
  name: "BankIdForm",
  props: {
    hideCancelControl: {
      type: Boolean
    }
  },
  data() {
    return {
      authSessionEndState: false,
      authSessionErrorState: false,
      authSessionErrorCode: '',
      authSessionErrorText: '',
      authSessionErrorParam: null,

      qrHash: null,
      getQaInterval: null,
      checkSessionInterval: null
    }
  },
  components: {
    QrcodeVue
  },
  computed: {
    ...mapState(bankIdStore, [
        'bankIdStartToken',
        'successCallback'
    ]),
    bankIdAuthStartToken() {
      return this.bankIdStartToken;
    },
    openDesktopUrl() {
      return 'bankid:///?autostarttoken=' + this.bankIdAuthStartToken + '&redirect=null'
    }
  },
  methods: {
    ...mapActions(bankIdStore, [
        'requestNewAuthSession'
    ]),
    requestCheckSessionState(successCallback, failCallback) {
      axios.get('/api/bankid/auth/check').then((res)=> {
        if (successCallback) {
          successCallback(res);
        }
      }).catch((error)=> {
        if (failCallback) {
          failCallback(error);
        }
      });
    },
    getQrCodeHash(successCallback, failCallback) {
      axios.get('/api/bankid/auth/qr').then((res)=> {
        this.qrHash = res.data;

        if (successCallback) {
          successCallback(res);
        }
      }).catch(()=> {
        if (failCallback) {
          failCallback();
        }
      });

    },
    repeatInitAuthSession() {
      this.requestNewAuthSession( ()=> {
        this.initAuthSession();
      });
    },
    initAuthSession() {
      var delay = 1000;

      this.authSessionEndState = false;
      this.authSessionErrorState = false;
      this.authSessionErrorCode = false;
      this.authSessionErrorText = false;
      this.authSessionErrorParam = null;

      this.getQaInterval = setInterval(() => {
        this.getQrCodeHash( ()=> {
        },  ()=> {
          clearInterval(this.getQaInterval);
          this.authSessionEndState = true;
        });
      }, delay);

      this.checkSessionInterval = setInterval(() => {
        this.requestCheckSessionState( (res)=> {
          var status = res.status;

          if (status === 200) {
            setTimeout(()=> {
              clearInterval(this.getQaInterval);
              clearInterval(this.checkSessionInterval);
            }, 0);
            this.authLogin();
          }
        },  ()=> {
          clearInterval(this.checkSessionInterval);
          setTimeout(()=> {
            this.authSessionEndState = true;
          }, 1000);
        });
      }, delay);
    },
    cancelInitAuthSession() {
      axios.put(' /api/bankid/auth/cancel').then( ()=> {
        setTimeout(()=> {
          clearInterval(this.getQaInterval);
          clearInterval(this.checkSessionInterval);
        }, 0);

        this.$emit('on-cancel');
      })
    },
    authLogin() {
      axios.put('/api/bankid/auth/login').then( ()=> {
        if (this.successCallback) {
          this.successCallback();
        }
        this.$emit('on-cancel');
      }).catch((error)=> {
        var errorCode = error.response.data.code;

        if (errorCode === 409) {
          //Redirect to join form

          this.authSessionErrorState = true;
          this.authSessionErrorCode = errorCode;
          this.authSessionErrorText = '';
          this.authSessionErrorParam = error.response.data.conflictedEntityId;

        } else if (errorCode === 206) {
          //Redirect to become member
          // popupSrv.showRegistrationPopUp(false, true);
          // TODO
        }
      });
    },
    openDesktop() {
      window.open(this.openDesktopUrl, '_blank').focus();
    }
  },
  mounted() {
    this.initAuthSession();
  }
}
</script>

<template>
  <div class="bank-id-auth-form form-wrapper">
    <div class="bank-id-auth-form-title">
      <div class="bank-id-auth-form-title__icon"></div>
      <span class="bank-id-auth-form-title__text">
        {{$t('bankIdForm.title')}}
      </span>
    </div>
    <template v-if="authSessionEndState || authSessionErrorState">
      <div class="bank-id-qr bank-id-qr--end">
        <div class="bank-id-qr__alert">
          <i class="fa fa-exclamation" aria-hidden="true"></i>
        </div>
        <p class="bank-id-auth-form-desc mb_25">
          <template v-if="authSessionEndState">
            {{$t('bankIdForm.errorText')}}
            <a href=" https://install.bankid.com">https://install.bankid.com</a>.
          </template>
          <template v-else-if="authSessionErrorState">
            <template v-if="authSessionErrorCode === 409">
              {{$t('bankIdForm.youPersonalNumberConflict.p1')}}
              <a :href="'/' + authSessionErrorParam" target="_blank" class="text_semi-bold">
                {{$t('bankIdForm.youPersonalNumberConflict.p2')}}
              </a>
            </template>
            <template v-else>
              {{authSessionErrorText}}
            </template>
          </template>
        </p>
        <div class="bank-id-auth-form-actions">
          <button type="button"
                  @click="repeatInitAuthSession"
                  class="btn btn--primary"
          >
            {{$t('bankIdForm.tryAgain')}}
          </button>
          <button v-if="!hideCancelControl"
                  type="button"
                  @click="cancelInitAuthSession"
                  class="btn"
          >
            {{$t('bankIdForm.cancel')}}
          </button>
        </div>
      </div>
    </template>
    <template v-else>
      <p class="bank-id-auth-form-desc">
        {{$t('bankIdForm.descText')}}
      </p>
      <div class="bank-id-qr">
        <span class="bank-id-qr__title">
          {{$t('bankIdForm.startScanQr')}}
        </span>
        <qrcode-vue :value="qrHash"
                    class="bank-id-qr__code"
        ></qrcode-vue>
        <span class="bank-id-qr__link" @click="openDesktop">
          {{$t('bankIdForm.openDeviceLink')}}
        </span>
      </div>
    </template>
  </div>
</template>

<style lang="scss" scoped>
.bank-id-auth-form {
  max-width: 595px;
  .bank-id-auth-form-title {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    &__icon {
      display: block;
      width: 34px;
      height: 34px;
      background: url("~@/assets/img/bankId/authenticate.svg") no-repeat;
      margin-right: 12px;
    }
    &__text {
      font-size: 14px;
      color: #4f4f4f;
      font-weight: 600;
    }
  }

  .bank-id-auth-form-desc {
    display: inline-block;
    margin-bottom: 25px;
    font-size: 14px;
    font-family: 'Open Sans', Arial, sans-serif;
    text-align: left;
    line-height: 18px;
    color: #4f4f4f;

    a {
      color: #7835a6;
      text-decoration: none;
    }
  }

  .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 26px;
    min-width: 90px;
    padding: 0 5px;
    font-size: 14px;
    font-weight: 600;
    border-radius: 2px;
    color: #7130a0;
    border: none;
    outline: none;
    text-transform: uppercase;
    cursor: pointer;
    background: #F0F0F0;
    font-family: 'Open Sans', Arial, sans-serif;
    &--primary {
      margin-right: 15px;
      color: #ffffff;
      background: #7130a0;
    }
  }

  .bank-id-qr {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__title {
      margin-bottom: 20px;
      font-weight: 600;
    }
    &__code {
      margin-bottom: 20px;

      canvas {
        width: 125px !important;
        height: 125px !important;
      }
    }
    &__link {
      font-weight: 600;
      text-decoration: underline;
      cursor: pointer;
    }

    &--end {
      .bank-id-qr {
        &__alert {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100px;
          height: 100px;
          margin: 10px 0 30px;
          border-radius: 100px;
          background: #FC7E7E;
          color: #193E4F;
          font-size: 45px;
        }
      }

      .bank-id-auth-form-actions {
        display: flex;
      }
    }
  }
}
</style>