<script>
import {mapActions, mapState} from "pinia";
import {authStore} from "@/store/authStore";
import { useVuelidate } from '@vuelidate/core'
import {required, email, helpers } from '@vuelidate/validators'
import qs from 'qs';
import i18n from '@/i18n';
import Dialog from "primevue/dialog";

export default {
  name: "BaseLoginModal",
  setup () {
    return { v$: useVuelidate() }
  },
  components: {
    Dialog,
  },
  data() {
    return {
      maxCharCount: 60,
      loginEmail: '',
      showLoginEmailCharCount: false,
      loginPassword: '',
      showLoginPasswordCharCount: false
    };
  },
  validations () {
    return {
      loginEmail: {
        required: helpers.withMessage(i18n.global.t('validations.required'), required),
        email: helpers.withMessage(i18n.global.t('validations.email'), email)
      },
      loginPassword: {
        required: helpers.withMessage(i18n.global.t('validations.required'), required)
      }
    }
  },
  computed: {
    ...mapState(authStore, [
      'loginModalState',
      'managerProfileInfo',
      'userInfo',
      'hasUnsavedChanges'
    ]),
    modalState: {
      get() {
        return this.loginModalState;
      },
      set() {
        this.closeModal();
      },
    },
    hasChanges() {
      return this.loginEmail.length > 0 || this.loginPassword.length > 0;
    }
  },
  methods: {
    ...mapActions(authStore, [
      'loginAction',
      'authGetUserData',
      'getApprovedOrganizations',
      'getUnreadNewsCountAction',
      'getCriticalEventCountAction',
      'getManagerProfile',
      'toggleLoginModalState',
      'openResetPasswordModal',
      'openSignUpModal',
      'setUnsavedChanges',
      'toggleLeavePageModal'
    ]),
    loginProcessing() {
      this.getManagerProfile().then(()=> {
        if (this.managerProfileInfo && this.$route.name === 'Index') {
          this.$router.push('/managerLounge')
        }
      })
      if (this.userInfo) {
        this.$i18n.locale = this.userInfo.lang;
      } else {
        this.$i18n.locale = 'se';
      }
    },
    closeModal(ignoreChangesCheck) {
      if (this.hasChanges && !ignoreChangesCheck) {
        this.toggleLeavePageModal();
        this.setUnsavedChanges(true, this.leavePage);
      } else {
        this.leavePage();
      }
    },
    leavePage() {
      this.v$.$reset();
      this.loginEmail = '';
      this.loginPassword = '';
      this.toggleLoginModalState();
    },
    openResetPassModal() {
      this.toggleLoginModalState();
      this.openResetPasswordModal(()=> {
        this.toggleLoginModalState();
      });
    },
    openSignUp() {
      this.closeModal();
      this.openSignUpModal();
    },
    async login() {
      const formData = {
        j_username: this.loginEmail,
        j_password: this.loginPassword,
        _acegi_security_remember_me: 'on'
      };
      const isFormCorrect = await this.v$.$validate()

      this.v$.$touch();
      if (isFormCorrect) {
        this.loginAction(qs.stringify(formData)).then(() => {
          this.authGetUserData().then(()=>{
            this.getApprovedOrganizations();
            this.getUnreadNewsCountAction();
            this.getCriticalEventCountAction();
            this.loginProcessing();
          });
          this.closeModal(true);
        });
      }
    },
  }
}
</script>

<template>
  <Dialog v-model:visible="modalState"
          class="login-modal"
          modal
          :header="$t('auth.login')"
  >
    <div>
      <div class="field-wrap">
        <input type="text"
               v-model="loginEmail"
               class="form-control"
               :class="{'form-control--error': v$.loginEmail.$error}"
               @focus="showLoginEmailCharCount = true"
               @blur="showLoginEmailCharCount = false"
               @keyup.enter="login"
               :placeholder="$t('auth.eMail')"
               maxlength="60"
               autofocus
        />
        <span v-if="showLoginEmailCharCount" class="form-control__char-count">
          {{maxCharCount - loginEmail.length}}
        </span>
        <span v-for="error of v$.loginEmail.$errors" :key="error.$uid"
              class="form-control-error"
        >
          {{ error.$message }}
        </span>
      </div>
      <div class="field-wrap">
        <input type="password"
               v-model="loginPassword"
               class="form-control"
               :class="{'form-control--error': v$.loginPassword.$error}"
               @focus="showLoginPasswordCharCount = true"
               @blur="showLoginPasswordCharCount = false"
               @keyup.enter="login"
               :placeholder="$t('auth.password')"
               maxlength="60"
        />
        <span v-if="showLoginPasswordCharCount" class="form-control__char-count">
             {{maxCharCount - loginPassword.length}}
        </span>
        <span v-for="error of v$.loginPassword.$errors" :key="error.$uid"
              class="form-control-error"
        >
          {{ error.$message }}
        </span>
      </div>
      <div class="field-wrap">
        <span class="form-label form-label__link"
              @click="openResetPassModal"
        >
          {{$t('auth.forgotYourPassword')}}
        </span>
      </div>
      <div class="field-wrap">
        <span class="form-label">
          {{$t('auth.notMember')}}
          <span class="form-label form-label__link" @click="openSignUp">{{$t('auth.signUp')}}</span>
        </span>
      </div>
    </div>
    <template #footer>
      <button type="button" class="p-dialog-close-btn" @click="closeModal()">
        {{$t('common.buttons.cancel')}}
      </button>
      <button class="p-dialog-primary-btn" @click="login">
        {{$t('auth.login')}}
      </button>
    </template>
  </Dialog>
</template>

<style lang="scss">
.login-modal {
  min-width: 500px;
  .p-dialog-content {
    padding: 20px 30px 25px;
    .field-wrap {
      display: flex;
      align-items: center;
      position: relative;
      margin-bottom: 15px;
      .form-control {
        min-width: 205px;
        background: #fff;
        display: inline-block;
        font-size: 12px;
        border: 1px solid #cccccc;
        height: 30px;
        color: #4f4f4f;
        padding: 0 30px 0 8px;
        appearance: none;
        outline: none;
        &::placeholder {
          color: #bbbbbb;
        }
        &:focus {
          border-color: #999999;
          color: #000000;
        }
        &__char-count {
          position: absolute;
          left: 175px;
          top: 5px;
          padding-left: 7px;
          border-left: 1px solid #ccc;
          line-height: 20px;
          font-size: 12px;
          color: #4f4f4f;
        }
        &--error {
          border-color: #fe8f18;
        }
      }
      .form-control-error {
        display: block;
        margin-left: 15px;
        font-size: 12px;
        color: #fe8f18;
      }

      .form-label {
        font-size: 12px;
        &__link {
          color: #7835a6;
          font-weight: 600;
          cursor: pointer;
        }
      }
    }

  }
}

</style>