<script>
import {mapActions} from "pinia";
import {documentStore} from "@/store/documentStore";
import {argumentStore} from "@/store/argumentStore";
import BaseAvatar from "@/components/BaseAvatar/BaseAvatar.vue";
export default {
  name: "ArgumentsListItem",
  props: {
    argData: {
      type: Object
    },
    isSelectedArg: {
      type: Boolean
    }
  },
  components: {
    BaseAvatar
  },
  data() {
    return {
      argumentText: '',
      videoDoc: null
    }
  },
  computed: {
    argHasContent() {
      return !!this.argData.argumentDetailed;
    },
    argumentType() {
      return this.argData.argumentDetailed ? this.argData.argumentDetailed.type : null;
    },
    videoArgPreview() {
      if (this.videoDoc && this.videoDoc.status === 'PROCESSING') {
        return '/html/images/processing-video-animated.svg';
      }

      let preview = this.videoDoc ? this.videoDoc.attachments.filter((item) => {
        return item.name === 'VIDEO_PREVIEW';
      })[0] : null;

      return preview
          ? '/api/video/' + preview.hash + '/preview'
          : '/html/images/arguments/private-video.svg';
    },
  },
  methods: {
    ...mapActions(documentStore, [
      'getDocumentContent',
      'getTextDocumentContent'
    ]),
    ...mapActions(argumentStore, [
      'setActiveArgument',
    ]),
    getArgumentContent() {
      const argContentId = this.argData.argumentDetailed.contentId;

      if (this.argumentType === 'VIDEO') {
        this.getDocumentContent(argContentId).then((videoDoc) => {
          this.videoDoc = videoDoc.data;
        })
      } else if (this.argumentType === 'HTML_TEXT') {
        this.getTextDocumentContent(argContentId).then((documentText)=> {
          this.argumentText = documentText.data;
        });
      }
    },
    selectArgument() {
      this.setActiveArgument(this.argData);
    }
  },
  mounted() {
    if (this.argHasContent) {
      this.getArgumentContent();
    }
  }
}
</script>

<template>
<div class="arguments-list-item" @click="selectArgument"
     :class="{'arguments-list-item--active': isSelectedArg}"
>
  <div v-if="argumentType === 'VIDEO'"
       class="arguments-list-item__thumbnail arguments-list-item__thumbnail--video"
  >
    <img :src="videoArgPreview" class="preview-img" alt="">
  </div>
  <div v-else-if="argumentType === 'HTML_TEXT'"
       class="arguments-list-item__thumbnail arguments-list-item__thumbnail--text"
       v-html="argumentText"
  >
  </div>
  <span class="arguments-list-item__title ">{{argData.title}}</span>
  <div class="arguments-list-item__info">
    <div class="argument-item-creator" v-if="argData.creator">
      <base-avatar size="32"
                   :img-hash="argData.creator.imgHash"
                   class="argument-item-creator__avatar"
      />
      <router-link :to="{name: 'UserView', params: {id: argData.creator.id}}"
                   target="_blank"
                   class="argument-item-creator__name"
      >{{ argData.creator.name }}</router-link>
    </div>
  </div>
  <div class="arguments-list-item__rating-block">
    <div class="votes votes--agree">
<!--      <div class="votes__members">-->

<!--      </div>-->
      <font-awesome-icon :icon="['fas', 'thumbs-up']"
                         class="votes__icon"
      />
      <div class="votes__count">
        0
      </div>
    </div>
    <div class="votes votes--disagree">
<!--      <div class="votes__members">-->

<!--      </div>-->
      <font-awesome-icon :icon="['fas', 'thumbs-down']"
                         class="votes__icon"
      />
      <div class="votes__count">
        0
      </div>
    </div>
  </div>
</div>
</template>

<style lang="scss" scoped>
.arguments-list-item {
  display: flex;
  flex-direction: column;
  width: 257px;
  height: 249px;
  margin: 0 2.5px;
  padding: 5px;
  border-radius: 4px;
  cursor: pointer;

  &--active {
    background: #f1e8f8;
  }

  &:hover {
    background-color: #f1e8f8;
  }
  &__thumbnail {
    position: relative;
    margin-bottom: 5px;

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 45px;
      left: 98px;
      z-index: 1;
      min-width: 50px;
      height: 50px;
      background: url("~@/assets/img/proposal/video-play-btn-75.svg") center no-repeat;
      background-size: 100%;
    }
    &--text {
      width: 247px;
      height: 139px;
      margin: 0 0 5px;
      padding: 10px 15px;
      color: #fff;
      background-image: linear-gradient(119deg, #bc5bff, #8e4eba 99%);
      font-size: 16px;
      font-weight: 600;
      text-align: left;
      line-height: 1.6;
      letter-spacing: -.01px;
      word-break: break-all;
    }
    &--video {
      .preview-img {
        width: 247px;
        height: 139px;
      }
    }
   }
  &__title {
    display: inline-block;
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.43;
    color: #4f4f4f;
  }
  &__info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    .argument-item-creator {
      display: flex;
      align-items: center;

      &__avatar {
        margin-right: 5px;
      }
      &__name {
        font-weight: 600;
        font-size: 12px;
      }
    }
  }
  &__rating-block {
    display: flex;
    justify-content: space-between;

    .votes {
      display: flex;
      align-items: center;
      &--agree {
        color: #01c77e;
        .votes__members {
          margin-right: 2px;
        }
      }
      &--disagree {
        color: #e64407;
        .votes__members {
          margin-left: 4px;
        }
      }
      &__icon {
        margin-right: 5px;
      }
      &__members {
        display: flex;
        margin-left: 10px;
        .member {
          position: relative;
          left: -3px;
          width: 22px;
          height: 22px;
          background-color: #cccccc;
          border-radius: 22px;
          &:first-child {
            left: 0;
          }
          &:last-child {
            left: -6px;
          }
        }
      }
    }
  }
}
</style>