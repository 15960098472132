<script>
import ProgressBar from "primevue/progressbar";
import {mapState} from "pinia";
import {authStore} from "@/store/authStore";
export default {
  name: "ProposalArgumentPlayerControls",
  components: {
    ProgressBar
  },
  data() {
    return {
      progressbarVal: 0
    }
  },
  computed: {
    ...mapState(authStore, [
      'userLoggedIn',
      'userIsManager'
    ]),
    disabledReactionControls() {
      // return !this.userLoggedIn || this.userIsManager;
      return true;
    },
    reactionControlsInfoText() {
      let infoText = '';

      if (this.userIsManager) {
        infoText = 'Manager can not claim source';
      }

      return infoText;
    }
  },
  mounted() {

  }
}
</script>

<template>
<div class="argument-player-controls">
  <span class="argument-player-controls__title">Do you agree or disagree with this argument?</span>
  <div class="argument-player-controls__buttons">
    <div class="player-nav-control player-nav-control--prev"
         v-tooltip.top="'See previous argument'"
    >
      <font-awesome-icon :icon="['fas', 'step-backward']"/>
    </div>
    <div class="player-reaction-control player-reaction-control--agree"
         :class="{'disabled': disabledReactionControls}"
         v-tooltip.top="reactionControlsInfoText"
    >
      <font-awesome-icon :icon="['fas', 'thumbs-up']" class="player-reaction-control__icon"/>
      <span class="player-reaction-control__caption">Agree</span>
    </div>
    <div class="player-reaction-control player-reaction-control--disagree"
         :class="{'disabled': disabledReactionControls}"
         v-tooltip.top="reactionControlsInfoText"
    >
      <font-awesome-icon :icon="['fas', 'thumbs-down']" class="player-reaction-control__icon"/>
      <span class="player-reaction-control__caption">Disagree</span>
    </div>
    <div class="player-nav-control player-nav-control--next"
         v-tooltip.top="'See next argument'"
    >
      <font-awesome-icon :icon="['fas', 'step-forward']"/>
    </div>
  </div>
  <div class="argument-player-controls__ratio-bar">
    <progress-bar :value="progressbarVal"
                  :show-value="false"
                  :class="{'disabled': disabledReactionControls}"
    />
    <span class="progressbar-info">
      Nobody publicly agree or disagree with this argument yet.
    </span>
  </div>
</div>
</template>

<style lang="scss">
.argument-player-controls {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 8px 30px;

  &__title {
     margin-bottom: 10px;
     font-size: 16px;
     font-weight: 600;
     text-align: center;
     line-height: 1.5;
     letter-spacing: -.01px;
     color: #4f4f4f;
   }

  &__buttons {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    .player-nav-control {
      display: flex;
      width: 33px;
      height: 38px;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      border: 2px solid #f2f2f2;
      box-sizing: border-box;
      cursor: pointer;
      color: #4f4f4f;

      &:hover {
        box-shadow: 0 0 6px 0 rgba(79, 79, 79, .27);
        border: .5px solid #f2f2f2;
        background-color: #f1e8f8;
      }

      &__prev {

      }
      &__next {

      }
    }

    .player-reaction-control {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;
      height: 38px;
      max-width: 231px;
      position: relative;
      border-radius: 5px;
      box-shadow: 0 0 6px 0 rgba(79, 79, 79, .27);
      border: .5px solid #f2f2f2;
      box-sizing: border-box;
      cursor: pointer;

      &:hover {
        background-color: #e3e3e3;
        border: .5px solid #e3e3e3;
      }

      &__icon {
        margin-right: 6px;
      }

      &__caption {
        font-size: 14px;
        font-weight: 600;
      }

      &--agree {
        color: #01c77e;
      }

      &--disagree {
        color: #e64407;
      }

      &.disabled {
        border: 1px solid #d1d1d1;
        box-shadow: none;
        color: #d1d1d1 !important;
        background: #fff;
        cursor: default;
      }
    }
  }

  &__ratio-bar {
    .p-progressbar {
      height: 7px;
      margin-bottom: 5px;
      border-radius: 4px;
      background-color: #e64407;
      .p-progressbar-value {
        background-color: #01c77e;
      }

      &.disabled {
        background-color: #ccc;
        .p-progressbar-value {
          background-color: #ccc;
        }

      }
    }

    .progressbar-info {
      display: flex;
      width: 100%;
      justify-content: center;
      min-height: 21px;
      font-size: 12px;
      line-height: 1.5;
      letter-spacing: -.01px;
      text-align: center;
      color: #3c3c3c
    }

  }
}
</style>