<script>
import i18n from "@/i18n";
import moment from "moment-timezone";
import {useVuelidate} from "@vuelidate/core";
import {mapActions, mapState} from "pinia";
import {helpers, minLength, required} from "@vuelidate/validators";
import {proposalStore} from "@/store/proposalStore";
import BaseAvatar from "@/components/BaseAvatar/BaseAvatar.vue";

export default {
  name: "EditProposalGeneralInfo",
  setup () {
    return { v$: useVuelidate() }
  },
  components: {
    BaseAvatar
  },
  validations () {
    return {
      proposalHeader: {
        required: helpers.withMessage(i18n.global.t('validations.required'), required),
        minLength: minLength(5),
      },
    }
  },
  computed: {
    ...mapState(proposalStore, [
      'editedProposalData',
    ]),
    proposerName() {
      return this.editedProposalData.proposer.name
          || `${this.editedProposalData.proposer.firstName} ${this.editedProposalData.proposer.lastName}`
    },
    proposalCreateDate() {
      let dateLabel = 'To be set';
      if (this.editedProposalData.createDateTime) {
        let date = this.editedProposalData.createDateTime.substring(0, this.editedProposalData.createDateTime.indexOf("["));
        let timeZone = this.editedProposalData.createDateTime.replace( /(^.*\[|\].*$)/g, '' );
        dateLabel = moment(date).tz(timeZone).format('YYYY-MM-DD h:mm');
      }
      return dateLabel;
    },
    proposalIssueNumber() {
      return this.editedProposalData.issueNumber ? this.editedProposalData.issueNumber : 'To be set';
    },
    proposalHeader: {
      get: function () {
        return this.editedProposalData.title;
      },
      set: function (value) {
        this.setEditPropValue('title', value.replace(/\n/g, ''));
      }
    }
  },
  methods: {
    ...mapActions(proposalStore, [
      'setEditPropValue'
    ]),
  },
}
</script>

<template>
  <div class="edit-prop-section edit-prop-general">
    <base-avatar v-if="editedProposalData.proposer.imgHash"
                 :size="'XL'"
                 :img-hash="editedProposalData.proposer.imgHash"
                 class="edit-prop-general__avatar"
    />
    <base-avatar v-else
                 :size="'XL'"
                 :img-src="'/api/image/avatar'"
                 class="edit-prop-general__avatar"
    />
    <div class="edit-prop-general__info">
      <div class="edit-proposal-header-wrap"
           :class="{'edit-proposal-header-wrap--error': v$.proposalHeader.$error}"
      >
          <textarea v-model="proposalHeader"
                    @blur="v$.proposalHeader.$touch"
                    v-on:keydown.enter.prevent=""
                    class="edit-proposal-header"
                    :placeholder="$t('editProposal.titlePlaceholder')"
                    maxlength="140"
                    rows="2"
                    wrap="hard"
          ></textarea>
      </div>
      <div class="info-block">
        <div class="info-items-list">
          <div class="info-item">
          <span class="info-item__label">
            {{$t('editProposal.forum')}}
          </span>
            <a :href="`/forum/${editedProposalData.forum.id}`"
               target="_blank"
               class="info-item__link"
            >
              {{ editedProposalData.forum.name }}
            </a>
          </div>
          <i class="info-item-separator"></i>
          <div class="info-item">
          <span class="info-item__label">
            {{$t('editProposal.proposer')}}:
          </span>
            <router-link :to="{name: 'UserView', params: {id: editedProposalData.proposer.id}}"
                         target="_blank"
                         class="info-item__link"
            >{{ proposerName }}</router-link>
          </div>
        </div>
        <div class="info-items-list">
          <div class="info-item">
          <span class="info-item__label">
            {{$t('editProposal.created')}}
          </span>
            <span class="info-item__text">
            {{proposalCreateDate}}
          </span>
          </div>
          <i class="info-item-separator"></i>
          <div class="info-item">
          <span class="info-item__label">
            {{$t('editProposal.issueNumber')}}
          </span>
            <span class="info-item__text">
            {{proposalIssueNumber}}
          </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.edit-prop-general {
  display: flex;
  position: relative;
  flex-direction: row;
  height: 115px;
  &__avatar {
    margin-right: 15px;
    z-index: 1;
  }
  &__info {
    .edit-proposal-header-wrap {
      position: absolute;
      top: 8px ;
      left: 8px;
      width: 954px;
      height: 55px;
      border: solid 2px transparent;
      &--error {
        border: solid 2px #e60907;
      }
    }

    .edit-proposal-header{
      width: 680px;
      margin: 5px 0 10px 0;
      position: relative;
      left: 82px;
      border: none;
      font-family: 'Open Sans', Arial, sans-serif;
      font-size: 16px;
      font-weight: 600;
      line-height: normal;
      color: #4f4f4f;
      background: transparent;
      outline: none;
      resize: none;
    }

    .info-block {
      position: relative;
      top: 50px;
    }

    .info-items-list {
      display: flex;
      height: 20px;
      color: #3c3c3c;
    }

    .info-item {
      display: flex;
      align-items: center;
      font-size: 12px;
      font-weight: 400;

      &__label {
        margin-right: 5px;
      }
      &__text {

      }
      &__link {
        color: #7835a6;
        cursor: pointer;
        font-weight: 600;
        text-decoration: none;
      }
    }

    .info-item-separator {
      display: flex;
      align-items: center;
      &:after {
        margin: 0 6px;
        content: "\25CF";
        display: inline-block;
        font-size: 6px;
        color: #4f4f4f;
      }
    }
  }
}
</style>