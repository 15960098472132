<script>
import {mapActions, mapState} from "pinia";
import {proposalStore} from "@/store/proposalStore";
import {authStore} from "@/store/authStore";
import EditProposalGeneralInfo from "@/views/proposal/proposal-edit/EditProposalGeneralInfo.vue";
import EditProposalEnterActual from "@/views/proposal/proposal-edit/EditProposalEnterActual.vue";
import EditProposalArguments from "@/views/proposal/proposal-edit/EditProposalArguments.vue";
// import EditProposalSubjects from "@/views/proposal/proposal-edit/EditProposalSubjects.vue";
import EditProposalImportance from "@/views/proposal/proposal-edit/EditProposalImportance.vue";
// import EditProposalThumbnail from "@/views/proposal/proposal-edit/EditProposalThumbnail.vue";
import ActualProposalExamplesModal from "@/views/proposal/proposal-edit/ActualProposalExamplesModal.vue";

export default {
  name: "EditProposalView",
  components: {
    EditProposalGeneralInfo,
    EditProposalEnterActual,
    EditProposalArguments,
    // EditProposalSubjects,
    EditProposalImportance,
    // EditProposalThumbnail,
    ActualProposalExamplesModal
  },
  computed: {
    ...mapState(authStore, ['activeUserInfo']),
    ...mapState(proposalStore, [
      'editedProposalData',
      'proposalInfo',
      'actualExamplesModalState',
      'closeCreatePropFormView',
      'resetEditProposalViewData',
      'resetSaveProposalData',
      'setActualProposalText'
    ]),
  },
  methods: {
    ...mapActions(proposalStore, [
      'setEditedProposalData',
      'setProposalArguments',
      'setEditPropValue',
      'setSavePropValue',
      'processProposalSaving'
    ]),
    saveProposal(actionType) {
      const $editProposalGeneralInfo = this.$refs.editProposalGeneralInfo;
      const $editProposalEnterActual = this.$refs.editProposalEnterActual;
      const $editProposalArguments = this.$refs.editProposalArguments;

      this.setSavePropValue('proposerId', this.activeUserInfo.id);
      this.setSavePropValue('title', this.editedProposalData.title);
      this.setSavePropValue('importanceType', this.editedProposalData.importanceType);
      this.setSavePropValue('id', this.proposalInfo ? this.proposalInfo.id : null);
      this.setSavePropValue('status', this.proposalInfo.status);

      if (actionType === 'PUBLISH') {
        $editProposalGeneralInfo.v$.$touch();
        $editProposalEnterActual.v$.$touch();
        $editProposalArguments.validateArguments();

        if (!$editProposalGeneralInfo.v$.$invalid &&
            !$editProposalEnterActual.v$.$invalid &&
            $editProposalArguments.readyForSave) {
          this.processProposalSaving(actionType);
        }
      } else {
        $editProposalGeneralInfo.v$.$touch();

        if (!$editProposalGeneralInfo.v$.$invalid) {
          this.processProposalSaving();
        }
      }
    }
  },
  beforeMount() {
    this.resetEditProposalViewData();
    this.resetSaveProposalData();
    this.setActualProposalText('');

    if (this.proposalInfo) {
      this.setEditedProposalData(this.proposalInfo)
    } else {
      this.setEditPropValue('proposer', this.activeUserInfo)
      this.setEditPropValue('forum', {
        id: 1,
        imgHash: "bafa30dc22474dcdf22d65c27298e66092b696fd",
        name: "Riksdagen",
        type: "FORUM"
      })
    }
  },
  beforeUnmount() {
    this.resetEditProposalViewData();
    this.resetSaveProposalData();
    this.closeCreatePropFormView();
    this.setActualProposalText('');
  },
  mounted() {

    console.log(this.$refs)
  }
}
</script>

<template>
  <div class="edit-proposal-view-wrap">
    <div class="edit-proposal-view">
      <edit-proposal-general-info ref="editProposalGeneralInfo"/>
      <edit-proposal-enter-actual ref="editProposalEnterActual"/>
      <edit-proposal-arguments ref="editProposalArguments"/>
<!--      <edit-proposal-subjects-->
<!--          :subjects-list="editedProposalData.subjects"-->
<!--      />-->
      <edit-proposal-importance/>
<!--      <edit-proposal-thumbnail/>-->
    </div>
    <actual-proposal-examples-modal :dialog-state="actualExamplesModalState"/>
  </div>
</template>

<style lang="scss">
.edit-proposal-view {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 150px;
  background: #E9EAED;
}

.edit-prop-section {
  width: 970px;
  margin-bottom: 10px;
  padding: 15px;
  background: #fff;
  box-sizing: border-box;
  box-shadow: 0 2px 8px 0 rgba(79, 79, 79, 0.15);

  &__title {
    margin-bottom: 25px;
    font-size: 16px;
    font-weight: 600;
    color: #4f4f4f
  }
}

</style>