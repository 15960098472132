<script>
import {mapState} from "pinia";
import {proposalStore} from "@/store/proposalStore";
import ProposalArgumentVideoPlayer from "@/views/proposal/proposal-argument/ProposalArgumentVideoPlayer.vue";
import ProposalArgumentTextPlayer from "@/views/proposal/proposal-argument/ProposalArgumentTextPlayer.vue";
import ProposalArgumentPlayerControls from "@/views/proposal/proposal-argument/ProposalArgumentPlayerControls.vue";
import {argumentStore} from "@/store/argumentStore";
export default {
  name: "ProposalArgument",
  props: {
    argId: {
      type: Number
    },
    proposalId: {
      type: String
    }
  },
  components: {
    ProposalArgumentVideoPlayer,
    ProposalArgumentTextPlayer,
    ProposalArgumentPlayerControls
  },
  computed: {
    ...mapState(argumentStore, [
        'activeArgument'
    ]),
    ...mapState(proposalStore, [
      'proposalArguments'
    ]),
    argType() {
      return this.activeArgument.argumentDetailed.type;
    },
    argContent() {
      return this.activeArgument.argumentDetailed;
    }
  },
  methods: {

  },
  mounted() {

  }
}
</script>

<template>
<div class="argument-section">
  <div v-if="activeArgument" class="argument-player-wrap">
    <proposal-argument-video-player v-if="argType === 'VIDEO'"
                                    :content="argContent"
    />
    <proposal-argument-text-player v-else-if="argType === 'HTML_TEXT'"
                                   :content="argContent"
    />
    <proposal-argument-player-controls/>
  </div>
  <div class="argument-sources-wrap"></div>
</div>
</template>

<style lang="scss" scoped>
.argument-section {
  display: flex;
  height: 510px;
  margin-bottom: 10px;
  background: #fff;

  .argument-player-wrap {
    width: 635px;
  }

  .argument-sources-wrap {
    padding-top: 10px;
  }
}

</style>