import { createWebHistory, createRouter} from 'vue-router';
import BaseIframeView from "@/components/BaseIframeView.vue";

import ProposalView from "@/views/ProposalView.vue";
import ProposalCreateView from "@/views/proposal/proposal-create/ProposalCreateView.vue";
import NewsFeedView from "@/views/NewsFeedView.vue";
import OrganizationView from "@/views/OrganizationView.vue";
import UserMyDesktopView from "@/views/UserMyDesktopView.vue";
import AboutView from "@/views/AboutView.vue";
import PrivacyPolicyView from "@/views/PrivacyPolicyView.vue";
import UserTermsView from "@/views/UserTermsView.vue";
import CookiePolicyView from "@/views/CookiePolicyView.vue";

import ManagerLoungeView from "@/views/manager/ManagerLoungeView.vue";
import ManagerKnowledgeAreaView from "@/views/manager/ManagerKnowledgeAreaView.vue";

import BecomeManagerView from "@/views/BecomeManagerView.vue";


const router = createRouter({
    routes: [
        {
            path: '/',
            name: 'Index',
            component: NewsFeedView,
            framePath: '/',
        },
        {
            path: '/proposal/:id/:version?',
            name: 'ProposalView',
            component: ProposalView,
            framePath: '/proposal/'
        },
        {
            path: '/showcreateproposal',
            name: 'ProposalCreateView',
            component: ProposalCreateView,
        },
        {
            path: '/org/:id',
            name: 'OrganizationView',
            component: OrganizationView,
        },
        {
            path: '/user/:id',
            name: 'UserView',
            component: UserMyDesktopView,
            framePath: '/user/'
        },
        {
            path: '/:id/mydesktop',
            name: 'UserMyDesktopView',
            component: UserMyDesktopView,
        },
        {
            path: '/about',
            name: 'About',
            component: AboutView,
            framePath: '/about',
        },
        {
            path: '/privacypolicy',
            component: PrivacyPolicyView,
            framePath: '/privacypolicy',
        },
        {
            path: '/userterms',
            component: UserTermsView,
            framePath: '/userterms',
        },
        {
            path: '/cookiepolicy',
            component: CookiePolicyView,
            framePath: '/cookiepolicy',
        },
        {
            path: '/managerLounge',
            component: ManagerLoungeView,
            framePath: '/managerLounge',
        },
        {
            path: '/managerKnowledgeArea',
            component: ManagerKnowledgeAreaView,
            framePath: '/managerKnowledgeArea',
        },
        {
            path: '/becomeManager',
            component: BecomeManagerView,
            framePath: '/becomeManager',
        },
        {
            path: '/:pathMatch(.*)*',
            component: BaseIframeView
        }
    ],
    history: createWebHistory()
});

export default router;