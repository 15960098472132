import { defineStore } from 'pinia';
import axios from 'axios';
export const argumentStore = defineStore({
    id: 'argumentStore',
    state: () => ({
        activeArgument: null,
    }),
    actions: {
        setActiveArgument(argument) {
            this.activeArgument = argument;
        },
        getArguments(proposalId) {
            return axios.get(`/api/arguments?page=0&proposalIds=${proposalId}&size=100`);
        },
        createNewArgument(data) {
            return axios.post(`/api/arguments`, data);
        },
        updateArgument(argId, data) {
            return axios.put(`/api/arguments/${argId}`, data);
        },
        setArgumentContent(argumentId, contentId) {
            return axios.put(`/api/arguments/${argumentId}/detailed?contentId=${contentId}` );
        }
    }
})