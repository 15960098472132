<script>
import i18n from "@/i18n";
import {mapActions, mapState} from "pinia";
import {proposalStore} from "@/store/proposalStore";

export default {
  name: "EditProposalImportance",
  data() {
    return {
      importanceItems: [
        {
          code: 'UNIMPORTANT',
          title: i18n.global.t('importance.unimportant'),
          index: 1
        },
        {
          code: 'SOME_IMPORTANCE',
          title: i18n.global.t('importance.someImportance'),
          index: 2
        },
        {
          code: 'IMPORTANT',
          title: i18n.global.t('importance.important'),
          index: 3
        },
        {
          code: 'VERY_IMPORTANT',
          title: i18n.global.t('importance.veryImportant'),
          index: 4
        },
        {
          code: 'MATTER_OF_THE_HEART',
          title: i18n.global.t('importance.matterOfHeart'),
          index: 5
        },
      ],
      activeItem:  {
        code: 'MATTER_OF_THE_HEART',
        title: i18n.global.t('importance.matterOfHeart'),
        index: 5
      },
      tmpActiveItem: null
    }
  },
  computed: {
    ...mapState(proposalStore, [
      'editedProposalData',
    ]),
    importanceLabel() {
      let label = '';
      if (this.tmpActiveItem) {
        label = this.tmpActiveItem.title;
      } else {
        label = this.activeItem.title;
      }

      return label
    },
    activeItemIndex() {
      let index = '';
      if (this.tmpActiveItem) {
        index = this.tmpActiveItem.index;
      } else {
        index = this.activeItem.index;
      }

      return index;
    }
  },
  methods: {
    ...mapActions(proposalStore, [
      'setEditPropValue'
    ]),
    setActiveItem(item) {
      this.activeItem = item;
      this.setEditPropValue('importanceType', item.code);
    }
  },
  beforeMount() {
    if (this.editedProposalData.importanceType) {
      this.activeItem = this.importanceItems.filter(type => {
        return type.code === this.editedProposalData.importanceType;
      })[0];
    }
  },
}
</script>

<template>
  <div class="edit-prop-section importance-section">
    <span class="edit-prop-section__title">
      {{$t('importance.howImportantIs')}}
    </span>
    <div class="importance-select-wrap">
      <div class="importance-select">
        <span class="importance-select__title">
          {{importanceLabel}}
        </span>
        <ul class="importance-select__list"
            @mouseleave="tmpActiveItem = null"
        >
          <li v-for="item in importanceItems"
              class="set-importance "
              :class="{'set-importance--active': (item.index <= activeItemIndex)}"
              :key="item.code"
              @mouseover="tmpActiveItem = item"
              @click="setActiveItem(item)"
          ></li>
        </ul>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.importance-section {
  height: 150px;

  .importance-select-wrap {
    .importance-select {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 300px;
      height: 72px;
      margin: 15px 0 0 320px;
      padding: 7px 55px 15px;
      border-radius: 2px;
      box-shadow: 0 2px 12px 0 rgba(215, 215, 215, 0.5);
      border: solid 1px #f2f2f2;
      background-color: #fff;
      &__title {
        display: inline-block;
        margin-bottom: 10px;
        font-size: 12px;
        color: #4f4f4f;
        text-align: center;
      }
      &__list {
        display: flex;
        justify-content: space-between;

        .set-importance {
          width: 26px;
          height: 22px;
          padding: 0 7.5px;
          background-image: url("~@/assets/img/proposal/sprite-heart.svg") ;
          background-size: 111px;
          background-repeat: no-repeat;
          background-position: -35px -36px;
          cursor: pointer;
          &--active {
            background-position: -35px -143px;
          }
          &:hover {
            background-position: -35px -143px;
          }
        }
      }
    }
  }
}
</style>