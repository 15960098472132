import {defineStore, mapActions} from 'pinia';
import axios from 'axios';
import router from "@/router";
import { documentStore } from "@/store/documentStore";
import {baseLoaderStore} from "@/store/baseLoaderStore";
import {argumentStore} from "@/store/argumentStore";

export const proposalStore = defineStore({
    id: 'proposalStore',
    state: () => ({
        proposalInfo: null,
        proposalNotAvailable: false,
        proposalOpinionsOfOthers: [],
        lifeCycleModalState: false,
        actualProposalModalState: false,
        actualProposalContent: null,
        proposersModalState: false,

        //Create proposal
        searchPropString: '',
        searchingState: false,
        existingProposals: [],
        createPropFormViewState: false,
        actualExamplesModalState: false,

        //Edit proposal
        actualProposalHasChanged: false,
        editProposalViewState: false,
        editedProposalData: {},
        selectedActualType: { name: 'Add text', value: 'HTML_TEXT' },
        actualProposalTextVal: '',
        actualProposalFile: null,
        saveProposalData: {
            forum: 1,
            creatorType: "USER",
            importanceType: "IMPORTANT",
        },

        //Proposal arguments
        proposalArguments: []
    }),
    actions: {
        ...mapActions(baseLoaderStore, [
            'showBaseLoader',
            'hideBaseLoader'
        ]),
        ...mapActions(documentStore, [
            'createTextDocument',
            'updateTextDocument',
            'createAttachmentDocument',
            'updateAttachmentDocument'
        ]),
        ...mapActions(argumentStore, [
            'createNewArgument',
            'updateArgument',
            'setArgumentContent',
            'getArguments'
        ]),
        setAsModified(entityName) {
            this[entityName] = true;
        },
        resetEditProposalViewData() {
            this.editedProposalData = {};
            this.actualProposalHasChanged = false;
            this.proposalArguments = [];
        },
        resetSaveProposalData() {
            this.saveProposalData = {
                forum: 1,
                creatorType: "USER",
                importanceType: "IMPORTANT"
            }
        },

        // Actual proposal
        setActualProposalType(type) {
            this.selectedActualType = type;
        },
        setActualProposalText(text) {
            this.actualProposalTextVal = text;
        },
        setActualProposalFile(file) {
            this.actualProposalFile = file;
        },
        setUpdateActualProposalAction(proposalId, contentId) {
            return axios.put(`/api/proposals/${proposalId}/actual?contentId=${contentId}`);
        },
        setUpdateTextActualProposal(contentId) {
            if (contentId) {
                return  this.updateTextDocument(contentId, {
                    mimeType: "text/html",
                    text: this.actualProposalTextVal,
                    title: "textDoc",
                });
            } else {
                return this.createTextDocument({
                    mimeType: "text/html",
                    text: this.actualProposalTextVal,
                    title: "textDoc",
                }).then((resp)=> {
                    return this.setUpdateActualProposalAction(this.editedProposalData.id, resp.data.id)
                        .then((res)=> {
                            this.proposalInfo = res.data;
                            this.editedProposalData = res.data;
                        });
                });
            }
        },
        setUpdatePdfActualProposal(contentId) {
            if (contentId) {
                const attachmentId = this.editedProposalData.actualProposal.attachments[0].id;
                this.updateAttachmentDocument(contentId, attachmentId, this.actualProposalFile)
                    .then((resp)=> {
                        this.setActualProposalFile(resp.data.attachments[0]);
                        this.proposalInfo.actualProposal = resp.data;
                    });
            } else {
                this.createAttachmentDocument(this.actualProposalFile, "PDF", "Actual proposal file")
                    .then((resp)=> {
                        this.setUpdateActualProposalAction(this.proposalInfo.id, resp.data.id)
                            .then((res)=> {
                                this.proposalInfo = res.data;
                                this.editedProposalData = res.data;
                            });
                });
            }
        },
        processActualProposalSaving() {
            const contentId = this.proposalInfo.actualProposal ? this.proposalInfo.actualProposal.contentId : null;

            if (this.actualProposalHasChanged) {
                if (this.selectedActualType.value === 'HTML_TEXT') {
                    if (this.actualProposalTextVal) {
                        return this.setUpdateTextActualProposal(contentId);
                    }
                } else if (this.selectedActualType.value === 'PDF') {
                    if (this.actualProposalFile) {
                        return this.setUpdatePdfActualProposal(contentId);
                    }
                }
            } else {
                return Promise.resolve();
            }
        },
        async processArgumentsSaving() {
            let readyForSave = [];

            this.proposalArguments.forEach((arg) => {
                const argHasContent = !!arg.argumentDetailed;

                if (!arg.changedTitle && !arg.changedContent) {
                    return false;
                }

                let argData = {
                    "creatorId": arg.creatorId || arg.creator.id,
                    "creatorType": arg.creatorType || arg.creator.type,
                    "order": arg.order,
                    "proposalId": this.editedProposalData.id,
                    "title": arg.title
                };

                if (arg.id) {
                    delete argData.proposalId;
                    argData.argumentNumber = arg.argumentNumber;

                    // TODO add DRAFT arg check
                    readyForSave.push(this.createNewArgument(argData).then((argResp)=> {
                        if (arg.type === 'TEXT') {
                            if (argHasContent) {
                                return this.updateTextDocument(argResp.data.argumentDetailed.contentId, {
                                    mimeType: "text/html",
                                    text: arg.argText,
                                    title: "textDoc",
                                });
                            } else {
                                return this.createTextDocument({
                                    mimeType: "text/html",
                                    text: arg.argText,
                                    title: "textDoc",
                                }).then((docResp)=> {
                                    return this.setArgumentContent(argResp.data.id, docResp.data.id);
                                });
                            }

                        } else if (arg.type === 'VIDEO') {
                            //TOOD
                        }
                    }));
                } else {
                    readyForSave.push(this.createNewArgument(argData).then((argResp)=> {
                        if (arg.type === 'TEXT') {
                            return this.createTextDocument({
                                mimeType: "text/html",
                                text: arg.argText,
                                title: "textDoc",
                            }).then((docResp)=> {
                                return this.setArgumentContent(argResp.data.id, docResp.data.id);
                            })
                        } else if (arg.type === 'VIDEO') {
                            if (arg.attachedVideoDoc) {
                                return this.setArgumentContent(argResp.data.id, arg.attachedVideoDoc.id);
                            }
                        }
                    }))
                }
            });

            return await Promise.all(readyForSave);
        },
        async processProposalSaving(actionType) {
            let readyForSave = [];

            this.showBaseLoader();
            if (this.saveProposalData.status === 'DRAFT') {
                readyForSave.push(await this.processActualProposalSaving());
                readyForSave.push(await this.processArgumentsSaving());
                await Promise.all(readyForSave).then(()=> {
                    if (actionType === 'PUBLISH') {
                        this.publishProposal();
                    } else {
                        this.saveProposal();
                    }
                });
            } else {
                this.publishNewVersionProposal();
            }
        },
        saveProposal() {
            this.updateProposalAction().then(()=> {
                this.successfullySaveProposal();
            });
        },
        publishProposal() {
            return this.publishProposalAction().then(()=> {
                this.successfullySaveProposal();
            });
        },
        async publishNewVersionProposal() {
            this.setSavePropValue('issueNumber', this.proposalInfo.issueNumber);
            await this.createProposalAction().then(()=> {
                this.processActualProposalSaving().then(()=> {
                    this.processArgumentsSaving().then(()=> {
                        this.publishProposal();
                    })
                })
            });
        },
        successfullySaveProposal() {
            this.resetEditProposalViewData();
            this.resetSaveProposalData();
            this.setProposalArguments([]);
            this.closeEditProposalView();
            this.closeCreatePropFormView();
            router.push({ name: 'ProposalView', params: { id: this.proposalInfo.id } });
            this.hideBaseLoader();
        },
        getProposalInfoByIssueNumber(proposalIssueNumber) {
            return axios.get(`/api/proposals?issueNumbers=${proposalIssueNumber}&page=0&size=10&statuses=CURRENT&statuses=ARCHIVED`)
                .then((res) => {
                    let proposalId = null;
                    if (res.data.content.length > 0) {
                        proposalId = res.data.content[0].id;
                    } else {
                        this.proposalNotAvailable = true;
                    }

                    if (proposalId) {
                        return this.getProposalInfo(proposalId);
                    }
                })
                .catch(error => console.log(error));
        },
        getProposalInfo(proposalId) {
            return axios.get(`/api/proposals/${proposalId}`)
                .then((res) => {
                    this.proposalInfo = res.data;
                })
                .catch((error) => {
                    const errorStatus = error.response.status;
                    this.proposalNotAvailable = (errorStatus === 400 || errorStatus === 404);
                }) ;
        },
        getActualTextContent(documentId) {
            return axios.get(`/api/cms/${documentId}/text`)
                .then((res) => {
                    this.actualProposalContent = res.data;
                })
                .catch(error => console.log(error));
        },
        getActualDocContent(docHash) {
            return axios.get(`/api/attachment/${docHash}/download`)
                .then((res) => {
                    this.actualProposalContent = res.data;
                })
                .catch(error => console.log(error));
        },
        getProposalOpinionsOfOthersAction(proposalId) {
            return axios.get(`/api/opinion/others?proposalId=${proposalId}`, {
                data: {},
            }).then((res) => {
                this.proposalOpinionsOfOthers = res.data;
            });
        },
        toggleLifeCycleModal() {
            this.lifeCycleModalState = !this.lifeCycleModalState;
        },
        toggleActualProposalModal() {
            this.actualProposalModalState = !this.actualProposalModalState;
        },
        toggleProposersModal() {
            this.proposersModalState = !this.proposersModalState;
        },

        //Create proposal
        setSearchPropString(val) {
            this.searchPropString = val;
        },
        searchExistingProposals() {
            this.searchingState = true;
            return axios.get(`/api/proposals?&title=` + this.searchPropString)
                .then((res) => {
                    this.existingProposals = res.data.content;
                    this.searchingState = false;
                })
                .catch(error => console.log(error));
        },
        openCreatePropFormView() {
            this.createPropFormViewState = true;
        },
        closeCreatePropFormView() {
            this.createPropFormViewState = false;
        },
        toggleActualExamplesModal() {
            this.actualExamplesModalState = !this.actualExamplesModalState;
        },

        //Edit proposal
        closeEditProposalView() {
            this.editProposalViewState = false;
        },
        toggleEditProposalState() {
            this.editProposalViewState = !this.editProposalViewState;
        },
        setEditedProposalData(data) {
            this.editedProposalData = data;
        },
        setEditPropValue(prop, value) {
            this.editedProposalData[prop] = value;
        },
        setSavePropValue(prop, value) {
            this.saveProposalData[prop] = value;
        },
        setProposalArguments(argumentsList) {
            this.proposalArguments = argumentsList;
        },
        addProposalArgument(argData) {
            this.proposalArguments.push(argData);
        },
        removeProposalArgument(argIndex) {
            const selectedArg = this.proposalArguments[argIndex];

            if (selectedArg.id) {
            //TODO remove action
            }

            this.proposalArguments = this.proposalArguments.splice(argIndex - 1, 1);
        },
        removeProposalArgumentAction() {
            //TODO remove action
        },
        async createProposalAction() {
            return axios.post(`/api/proposals`, this.saveProposalData).then((res)=> {
                this.proposalInfo = res.data;
                this.editedProposalData = res.data;
            });
        },
        updateProposalAction() {
            const proposalId = this.editedProposalData.id;
            return axios.put(`/api/proposals/${proposalId}`, this.saveProposalData).then((res) => {
                this.proposalInfo = res.data;
            });
        },
        publishProposalAction() {
            const proposalId = this.editedProposalData.id;

            return axios.put(`/api/proposals/${proposalId}/publish`, this.saveProposalData).then((res) => {
                this.proposalInfo = res.data;
            });
        }
    }
})