import { createApp } from 'vue';
import { createPinia } from 'pinia';
import i18n  from '@/i18n';
import App from './App.vue';
import router from './router';
import axios from 'axios';
import PrimeVue from "primevue/config";
import Tooltip from 'primevue/tooltip';
import VueAxios from "vue-axios";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import Nora from '@primevue/themes/nora';

library.add(fas, far);

const pinia = createPinia();

window.mainApp = createApp(App).
component("font-awesome-icon", FontAwesomeIcon).
use(router).
use(pinia).
use(i18n).
use(VueAxios, axios).
use(PrimeVue, {
    theme: {
        preset: Nora,
    }
}).
directive('tooltip', Tooltip).
mount('#app');