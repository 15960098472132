<script>

export default {
  name: "ProposalArgumentVideo",
  props: {
    content: {
      type: Object
    }
  },
  data() {
    return {
      player: null
    }
  },
  computed: {
    videoSource() {
      const videoContent = this.content.attachments.filter((item)=> {
        return item.type === 'VIDEO';
      })[0];

      return `/api/video/${videoContent.hash}/stream`;
    },
    videoPreview() {
      const videoPreview = this.content.attachments.filter((item)=> {
        return item.type === 'IMAGE';
      })[0];

      return `/api/video/${videoPreview.hash}/preview`;
    }
  },
  mounted() {

  },
}
</script>

<template>
  <div class="arg-video-player-wrap">
    <video id="videoPlayer" class="argument-video-player"
           controls
           preload="auto"
           autoplay="true"
           tabindex="-1"
           role="application"
           :src="videoSource"
           :poster="videoPreview"
    >
      <source :src="videoSource" type="video/mp4"/>
    </video>
  </div>
</template>

<style lang="scss" scoped>
.arg-video-player-wrap {
  .argument-video-player {
    width: 100%;
  }
}
</style>