<script>
import Dialog from "primevue/dialog";
import {mapActions, mapState} from "pinia";
import {authStore} from "@/store/authStore";
import {email, helpers, required} from "@vuelidate/validators";
import i18n from "@/i18n";
import {useVuelidate} from "@vuelidate/core";
export default {
  name: "ResetPasswordModal",
  setup () {
    return { v$: useVuelidate() }
  },
  components: {
    Dialog
  },
  data() {
    return {
      maxCharCount: 60,
      emailValue: '',
      emailValueCharCount: false,
    };
  },
  validations () {
    return {
      emailValue: {
        required: helpers.withMessage(i18n.global.t('validations.required'), required),
        email: helpers.withMessage(i18n.global.t('validations.email'), email)
      },
    }
  },
  computed: {
    ...mapState(authStore, [
      'resetPasswordModalState',
      'resetPasswordModalCloseCallback',
      'sendPasswordAction'
    ]),
    modalState: {
      get() {
        return this.resetPasswordModalState;
      },
      set() {
        this.closeModal();
      },
    },

  },
  methods: {
    ...mapActions(authStore, [
        'openResetPasswordModal',
        'closeResetPasswordModal'
    ]),
    closeModal() {
      this.v$.$reset();
      this.closeResetPasswordModal();
    },
    async sendReset() {
      const isFormCorrect = await this.v$.$validate()
      this.v$.emailValue.$touch();

      if (isFormCorrect) {
        this.sendPasswordAction(this.emailValue);
      }
    }
  }
}
</script>

<template>
  <Dialog v-model:visible="modalState"
          class="reset-password-modal"
          modal
          :header="$t('auth.sendPassword.changePassword')"
  >
    <div>
      <span class="form-info">
        {{$t('auth.sendPassword.changePasswordDesc')}}
      </span>
      <div class="field-wrap">
        <input type="text"
               v-model="emailValue"
               :placeholder="$t('auth.eMail')"
               class="form-control"
               :class="{'form-control--error': v$.emailValue.$error}"
               @focus="emailValueCharCount = true"
               @blur="emailValueCharCount = false"
               maxlength="60"
        />
        <span v-if="emailValueCharCount" class="form-control__char-count">
             {{maxCharCount - emailValue.length}}
        </span>
        <span v-for="error of v$.emailValue.$errors" :key="error.$uid"
              class="form-control-error"
        >
          {{ error.$message }}
        </span>
      </div>
    </div>
    <template #footer>
      <button type="button" class="p-dialog-close-btn" @click="closeModal">
        {{$t('common.buttons.cancel')}}
      </button>
      <button class="p-dialog-primary-btn" @click="sendReset">
        {{$t('auth.sendPassword.sendReset')}}
      </button>
    </template>
  </Dialog>
</template>

<style lang="scss">
.reset-password-modal {
  max-width: 500px;
  .p-dialog-content {
    padding: 20px 30px 25px;

    .form-info {
      display: inline-block;
      margin-bottom: 15px;
      font-size: 14px;
      line-height: 16px;
    }

    .field-wrap {
      display: flex;
      align-items: center;
      position: relative;
      margin-bottom: 15px;

      .form-control {
        min-width: 205px;
        background: #fff;
        display: inline-block;
        font-size: 12px;
        border: 1px solid #cccccc;
        height: 30px;
        color: #4f4f4f;
        padding: 0 30px 0 8px;
        appearance: none;
        outline: none;
        &::placeholder {
          color: #bbbbbb;
        }
        &:focus {
          border-color: #999999;
          color: #000000;
        }
        &__char-count {
          position: absolute;
          left: 175px;
          top: 5px;
          padding-left: 7px;
          border-left: 1px solid #ccc;
          line-height: 20px;
          font-size: 12px;
          color: #4f4f4f;
        }
        &--error {
          border-color: #fe8f18;
        }
      }

      .form-control-error {
        display: block;
        margin-left: 15px;
        font-size: 12px;
        color: #fe8f18;
      }
    }
  }
}
</style>