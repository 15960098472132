<script>
import {mapState} from "pinia";
import {proposalStore} from "@/store/proposalStore";

export default {
  name: "ProposalWarnMessage",
  computed: {
    ...mapState(proposalStore, ['proposalInfo']),
    latestProposalVersionId() {
      return this.proposalInfo.versions[0].id;
    }
  }
}
</script>

<template>
<div class="proposal-warn-message">
  <div class="warn-message">
    <font-awesome-icon :icon="['fas', 'triangle-exclamation']"
                       class="warn-message__icon"
    />
    <span class="warn-message__text">
      {{$t('proposal.warningMessage.notActualVersion.text')}}
    </span>
  </div>
  <RouterLink :to="`/proposal/${latestProposalVersionId}`" class="warn-link">
    {{$t('proposal.warningMessage.notActualVersion.linkText')}}
  </RouterLink>
</div>
</template>

<style lang="scss" scoped>
.proposal-warn-message {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
  position: relative;
  height: 27px;
  border-radius: 5px 5px 0 0;
  background-color: #fe8f18;
  .warn-message {
    display: flex;
    align-items: center;
    &__icon {
      margin-right: 8px;
      color: #fff;
      font-size: 15px;
    }
    &__text {
      color: #fff;
      font-size: 12px
    }
  }

  .warn-link {
    color: #fff;
    font-size: 12px;
    text-decoration: underline;
    cursor: pointer;
    &:hover {
      text-decoration: none;
    }
  }
}
</style>