<script>
import {mapState} from "pinia";
import {proposalStore} from "@/store/proposalStore";
import {argumentStore} from "@/store/argumentStore";

export default {
  name: "ProposalArgumentInfo",
  computed: {
    ...mapState(argumentStore, [
      'activeArgument'
    ]),
    ...mapState(proposalStore, [
        'proposalInfo'
    ]),
  }
}
</script>

<template>
  <div class="argument-info">
    <div class="argument-info__icon"></div>
    <div class="argument-info__text">
      <p>{{activeArgument.title}}</p>
      <p><span class="text-label">to proposal: {{proposalInfo.title}}</span></p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.argument-info {
  display: flex;
  &__icon {
    width: 35px;
    height: 35px;
    margin-right: 10px;
    background: url("~@/assets/img/arguments/pp-logo-video.svg") no-repeat;
  }
  &__text {
    font-size: 12px;
    font-weight: 600;
    color: #fff;
    .text-label {
      font-weight: 400;
    }
  }
}

</style>