<script>
import {mapActions} from "pinia";
import {documentStore} from "@/store/documentStore";
import ProposalArgumentInfo from "@/views/proposal/proposal-argument/ProposalArgumentInfo.vue";

export default {
  name: "ProposalArgumentTextPlayer",
  props: {
    content: {
      type: Object
    }
  },
  components: {
    ProposalArgumentInfo
  },
  data() {
    return {
      argumentText: ''
    }
  },
  methods: {
    ...mapActions(documentStore, [
      'getDocumentContent',
      'getTextDocumentContent'
    ]),
  },
  beforeMount() {
    this.getTextDocumentContent(this.content.contentId).then((documentText)=> {
      this.argumentText = documentText.data;
    });
  }
}
</script>

<template>
<div class="arg-text-player-wrap">
  <div class="arg-text-player">
    <div class="arg-text-player__info">
      <proposal-argument-info/>
    </div>
    <div class="arg-text-player__text" v-html="argumentText"></div>
  </div>
</div>
</template>

<style lang="scss"  scoped>
.arg-text-player-wrap {
  width: 636px;
  height: 358px;
  opacity: .9;
  background-image: linear-gradient(119deg, #bc5bff, #8e4eba 99%);
  box-sizing: border-box;

  .arg-text-player {
    &__info {
      padding: 5px 15px 0;
      margin-bottom: 10px;
    }
    &__text {
      padding: 5px 15px 15px;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      color: #fff;
    }
  }
}
</style>