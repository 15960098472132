<script>
import BaseIframe from "@/components/BaseIframe.vue";
export default {
  name: "ManagerLoungeView",
  components: {
    BaseIframe
  }
}
</script>

<template>
  <Base-iframe
      :src="'/managerLounge?_f=true'"
      :disable-loader="true"
  />
</template>
